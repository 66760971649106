import React from "react"
import { Fragment } from "react"
import Navbar from "./Navbar"
import Footer from "./Footer"
import SEO from "./seo"

const Layout = ({ children }) => {
  return (
    <Fragment>
      <SEO title="Title" />
      <Navbar />
      <div className="navbar-fix">{children}</div>
      <Footer />
    </Fragment>
  )
}

export default Layout
