import React, { Fragment } from "react"
import { Link } from "gatsby"
import Navbar from "./Navbar"

const SideMenu = () => {
  return (
    <Fragment>
      <aside className="menu sidemenu  box">
        <p className="menu-label">Proizvodi</p>
        <ul className="menu-list">
          <li>
            <Link to="/proizvodi/šavne-cevi">Šavne cevi</Link>
          </li>
          <li>
            <Link to="proizvodi/toplovaljani-lim">Toplovaljani limovi</Link>
            <ul>
              <li>
                <Link to="/proizvodi/toplovaljani-rebrasti-limovi">
                  Toplovaljani rebrasti limovi
                </Link>
              </li>
              <li>
                <Link to="/proizvodi/toplovaljani-kotlovski-limovi">
                  Toplovaljani kotlovski limovi
                </Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to="/proizvodi/hladnovaljani-limovi">
              Hladnovaljani limovi
            </Link>
          </li>
          <li>
            <Link to="/proizvodi/pocinkovani-limovi">Pocinkovani limovi</Link>
          </li>
          <li>
            <div className="dropdown is-left is-hoverable">
              <div
                className="dropdown-trigger is-flex"
                style={{ alignItems: "center" }}
              >
                <Link
                  to="/proizvodi/profili-nosači"
                  aria-controls="dropdown-menu2"
                >
                  Profili i nosači
                </Link>
                <span class="icon is-small">
                  <i class="fas fa-angle-down" aria-hidden="true"></i>
                </span>
              </div>
              <div className="dropdown-menu" id="dropdown-menu2" role="menu">
                <div className="dropdown-content">
                  <div className="dropdown-item">
                    <Link to="/proizvodi/profili-nosači/l-profili">
                      L Profili
                    </Link>
                  </div>
                  <div className="dropdown-item">
                    <Link to="/proizvodi/profili-nosači/u-nosači">
                      U Nosači
                    </Link>
                  </div>
                  <div className="dropdown-item">
                    <Link to="/proizvodi/profili-nosači/i-nosači">
                      I Nosači
                    </Link>
                  </div>
                  <div className="dropdown-item">
                    <Link to="/proizvodi/profili-nosači/ipe-nosači">
                      IPE Nosači
                    </Link>
                  </div>
                  <div className="dropdown-item">
                    <Link to="/proizvodi/profili-nosači/ipb-heb-nosači">
                      IPB - HEB Nosači
                    </Link>
                  </div>
                  <div className="dropdown-item">
                    <Link to="/proizvodi/profili-nosači/ipb-l-nosači">
                      IPB-L / HEA Nosači
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <Link to="/proizvodi/pocinkovane-cevi">Pocinkovane Cevi</Link>
          </li>
          <li>
            <Link to="/proizvodi/klizne-šine">Klizne šine</Link>
          </li>
          <li>
            <Link to="/proizvodi/profilisani-limovi">Profilisani limovi</Link>
          </li>

          <li>
            <Link to="/proizvodi/pvc-ojačanja">PVC ojačanja</Link>
          </li>
          <li>
            <Link to="/proizvodi/cd-ud-profili">CD/UD Profili</Link>
          </li>
          <li>
            <Link to="/proizvodi/čelične-stranice">Čelične stranice</Link>
          </li>
          <li>
            <Link to="/proizvodi/hop-profili">HOP Profili</Link>
          </li>
        </ul>
      </aside>
    </Fragment>
  )
}

export default SideMenu
