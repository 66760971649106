import React from "react"
import "../css/footer.css"
import Logo from "../images/Logo.png"

const Footer = () => {
  return (
    <div className="has-background-light">
      <footer className="container">
        <div className="upper-footer">
          <div className="left-footer">
            <img src={Logo} alt="" />
          </div>
          <div className="right-footer">
            <p>
              <i className="fas fa-phone"></i> +381 (0) 21 6414818
            </p>
            <p>
              <i className="fas fa-phone"></i> +381 (0) 21 6413337
            </p>
            <p>
              <i className="fas fa-envelope"></i> office@dakcomerc.co.rs
            </p>
            <p>
              <i className="fas fa-home"></i> Novi Sad, Srbija
            </p>
          </div>
        </div>
        <div className="bottom-footer">
          <p>&copy; Dak Comerc 2020.</p>
        </div>
      </footer>
    </div>
  )
}

export default Footer
