import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
          image
        }
      }
    }
  `)
  return (
    <Helmet>
      <title>
        DAK Comerc | proizvodnja šavnih cevi i servisni centar za obradu metala.
      </title>
      <meta name="description" content={data.site.siteMetadata.description} />
      <meta
        name="google-site-verification"
        content="ZVADg1eF2YCiPBE-MGTDkP-ghRZBlRNihxN9ibjB1lc"
      />
      <link rel="canonical" href={data.site.siteMetadata.siteUrl} />
      <link rel="apple-touch-icon" href="/images/logo.png" />
    </Helmet>
  )
}
export default SEO
