import React, { useEffect } from "react"
import SideMenu from "./SideMenu"
import AOS from "aos"

const ProductsLayout = ({ children }) => {
  useEffect(() => {
    AOS.init({
      once: true,
    })
  })
  return (
    <div>
      <div className="container" style={{ overflow: "hidden" }}>
        <div className="columns">
          <div className="column is-one-fifth is-hidden-mobile">
            <SideMenu />
          </div>
          <div
            data-aos="slide-up"
            data-aos-duration="900"
            className="column product-parrent"
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductsLayout
