import React, { Fragment } from "react"
import { slide as Menu } from "react-burger-menu"
import "../css/navbar.css"
import Logo from "../images/Logo.png"
import Gb from "../images/uk.svg"
import Srb from "../images/serbia.svg"
import { Link } from "gatsby"

const Navbar = () => {
  return (
    <Fragment>
      <Menu className="mobile-menu">
        <Link to="/" className="menu-item">
          Početna
        </Link>
        <Link to="/o-nama" className="menu-item">
          O nama
        </Link>
        <Link to="/proizvodi" className="menu-item">
          Proizvodi
        </Link>
        <Link to="/usluge" className="menu-item">
          Usluge
        </Link>
        <Link to="/karijera" className="menu-item">
          Karijera
        </Link>
        <Link to="/kontakt" className="menu-item">
          Kontakt
        </Link>
        {/* <div className="menu-item">
          <Link to="/EN">
            <img src={Gb} alt="" />
          </Link>
          <Link to="/">
            <img src={Srb} alt="" />
          </Link>
        </div> */}
      </Menu>
      <nav
        className="navbar is-fixed-top"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="container">
          <div>
            <Link to="/">
              <img src={Logo} />
            </Link>
          </div>
          <div id="navbarBasicExample" className="navbar-menu">
            <div className="navbar-start"></div>
            <div className="navbar-end">
              <Link to="/" className="navbar-item">
                Početna
              </Link>
              <Link to="/o-nama" className="navbar-item">
                O nama
              </Link>
              <div className="navbar-item has-dropdown is-hoverable">
                <Link to="/proizvodi" className="navbar-link">
                  Proizvodi
                </Link>
                <div className="navbar-dropdown">
                  <Link to="/proizvodi/šavne-cevi" className="navbar-item">
                    Šavne cevi
                  </Link>
                  <Link
                    to="/proizvodi/toplovaljani-lim"
                    className="navbar-item"
                  >
                    Toplovaljani limovi
                  </Link>
                  <Link
                    to="/proizvodi/toplovaljani-rebrasti-limovi"
                    className="navbar-item"
                  >
                    Toplovaljani rebrasti limovi
                  </Link>
                  <Link
                    to="/proizvodi/toplovaljani-kotlovski-limovi"
                    className="navbar-item"
                  >
                    Toplovaljani kotlovski limovi
                  </Link>
                  <Link
                    to="/proizvodi/hladnovaljani-limovi"
                    className="navbar-item"
                  >
                    Hladnovaljani limovi
                  </Link>
                  <Link
                    to="/proizvodi/pocinkovani-limovi"
                    className="navbar-item"
                  >
                    Pocinkovani limovi
                  </Link>
                  <div className="dropdown is-left is-hoverable">
                    <div
                      className="dropdown-trigger is-flex"
                      style={{ alignItems: "center" }}
                    >
                      <Link
                        to="/proizvodi/profili-nosači"
                        aria-haspopup="true"
                        aria-controls="dropdown-menu4"
                        className="navbar-item"
                      >
                        <span>Profili i nosači</span>
                      </Link>
                      <span className="icon is-small">
                        <i className="fas fa-angle-down" aria-hidden="true"></i>
                      </span>
                    </div>
                    <div className="dropdown-menu">
                      <div className="dropdown-content">
                        <div className="dropdown-item">
                          <Link
                            className="navbar-item"
                            to="/proizvodi/profili-nosači/l-profili"
                          >
                            L Profili
                          </Link>
                        </div>
                        <div className="dropdown-item">
                          <Link
                            className="navbar-item"
                            to="/proizvodi/profili-nosači/u-nosači"
                          >
                            U Nosači
                          </Link>
                        </div>
                        <div className="dropdown-item">
                          <Link
                            className="navbar-item"
                            to="/proizvodi/profili-nosači/i-nosači"
                          >
                            I Nosači
                          </Link>
                        </div>
                        <div className="dropdown-item">
                          <Link
                            className="navbar-item"
                            to="/proizvodi/profili-nosači/ipe-nosači"
                          >
                            IPE Nosači
                          </Link>
                        </div>
                        <div className="dropdown-item">
                          <Link
                            className="navbar-item"
                            to="/proizvodi/profili-nosači/ipb-heb-nosači"
                          >
                            IPB - HEB Nosači
                          </Link>
                        </div>
                        <div className="dropdown-item">
                          <Link
                            className="navbar-item"
                            to="/proizvodi/profili-nosači/ipb-l-nosači"
                          >
                            IPB-L / HEA Nosači
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Link
                    to="/proizvodi/pocinkovane-cevi"
                    className="navbar-item"
                  >
                    Pocinkovane cevi
                  </Link>
                  <Link to="/proizvodi/klizne-šine" className="navbar-item">
                    Klizne šine
                  </Link>
                  <Link
                    to="/proizvodi/profilisani-limovi"
                    className="navbar-item"
                  >
                    Profilisani limovi
                  </Link>

                  <Link to="/proizvodi/pvc-ojačanja" className="navbar-item">
                    PVC ojačanja
                  </Link>
                  <Link to="/proizvodi/cd-ud-profili" className="navbar-item">
                    CD/UD Profili
                  </Link>
                  <Link
                    to="/proizvodi/čelične-stranice"
                    className="navbar-item"
                  >
                    Čelične stranice
                  </Link>
                  <Link to="/proizvodi/hop-profili" className="navbar-item">
                    HOP Profili
                  </Link>
                </div>
              </div>
              <Link className="navbar-item" to="/usluge">
                Usluge
              </Link>
              <Link className="navbar-item" to="/karijera">
                Karijera
              </Link>
              <Link to="/kontakt" className="navbar-item">
                Kontakt
              </Link>
              {/* <Link to="/EN" className="navbar-language">
                <img src={Gb} alt="" />
              </Link>
              <Link to="/" className="navbar-language">
                <img src={Srb} alt="" />
              </Link> */}
            </div>
          </div>
        </div>
      </nav>
    </Fragment>
  )
}

export default Navbar
